import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

// https://www.aboutmonica.com/blog/2020-06-24-exploring-art-direction-in-gatsby

const LandingImage = ({ theme, breakpoints }) => {
  // Going in reverse: first the bigger returns without rendering...
  if (breakpoints.tabletLandscapeUp) return null;

  const {
    landingPhone,
    landingTabletDark,
    landingTabletLight,
  } = useStaticQuery(
    graphql`
      query {
        landingPhone: file(relativePath: { eq: "seasideCrop.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 520) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landingTabletDark: file(relativePath: { eq: "seasideCropDark.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 820) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landingTabletLight: file(relativePath: { eq: "seasideCropLight.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 820) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  if (breakpoints.tabletPortraitUp) {
    return (
      <Img
        className="mobile-image"
        style={{ width: `100%`, borderRadius: `6px` }}
        fluid={
          theme === "dark"
            ? landingTabletDark.childImageSharp.fluid
            : landingTabletLight.childImageSharp.fluid
        }
      />
    );
  }

  if (breakpoints.phoneOnly) {
    return (
      <Img
        className="mobile-image"
        style={{ width: `100%`, borderRadius: `6px` }}
        fluid={landingPhone.childImageSharp.fluid}
      />
    );
  }

  return null;
};

export default LandingImage;
