import React from "react";

const checkersOpacity = { dark: 0.05, light: 0.2 };

const CheckersSVG = ({ theme }) => (
  <svg width="100%" height="100%">
    <defs>
      <pattern
        id="checkers"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <path
          fill={`rgba(154,154,154,${
            theme === "dark" ? checkersOpacity.dark : checkersOpacity.light
          }`}
          d="M20,10L10,10L10,20L20,20L20,10ZM10,0L0,0L0,10L10,10L10,0Z"
        />
      </pattern>
      <linearGradient
        spreadMethod="pad"
        id="fadeGrad"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop offset="0" stopColor="white" stopOpacity="1" />
        <stop offset="0.7" stopColor="white" stopOpacity="1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <mask id="gradient-mask" x="0" y="0" width="100%" height="100%">
        <rect x="0" y="0" width="100%" height="100%" fill="url(#fadeGrad)" />
      </mask>
    </defs>

    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      fill="url(#checkers)"
      mask="url(#gradient-mask)"
    />
  </svg>
);

export default CheckersSVG;
