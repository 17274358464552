import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../contexts/ThemeContext";
import CheckersSVG from "./checkers";

const BackgroundStyled = styled.div`
  position: absolute;
  height: 400px;
  width: 100%;
  top: 0;
`;

const BackgroundImage = () => {
  const { colorScheme } = useContext(ThemeContext);
  return (
    <BackgroundStyled>
      <CheckersSVG theme={colorScheme} />
    </BackgroundStyled>
  );
};

export default BackgroundImage;
