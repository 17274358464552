import React, { useContext } from "react";
import { Button } from "@adobe/react-spectrum";
import styled from "styled-components";
import { navigate } from "gatsby";
import Apple from "../icons/Apple.svg";
import Windows from "../icons/Windows.svg";
import { TabsContext } from "../contexts/TabsContext";
import { PluginContext } from "../contexts/PluginContext";

const CTAStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;

  .icon {
    fill: var(--spectrum-global-color-gray-400);
    height: 15px;
    max-width: 20px;
    @media only screen and (min-width: 1200px) {
      height: 20px;
      max-width: 30px;
    }
  }

  .icon:first-of-type {
    margin-left: auto;
  }
`;

const LandingCTA = () => {
  const { setActiveTab } = useContext(TabsContext);
  const { activePlugin, setActivePlugin } = useContext(PluginContext);

  return (
    <CTAStyled>
      <Button
        variant="cta"
        onPress={() => {
          setActiveTab("plugins");
          if (activePlugin !== "allPlugins") {
            setActivePlugin("allPlugins");
          }
          navigate("/plugins");
        }}
      >
        Explore
      </Button>
      <Apple className="icon" />
      <Windows className="icon" />
    </CTAStyled>
  );
};

export default LandingCTA;
