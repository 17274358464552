import React, { useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Heading, Divider, Link as SpectrumLink } from "@adobe/react-spectrum";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import BackgroundImage from "../components/BackgroundImage";
import { ThemeContext } from "../contexts/ThemeContext";

import SEO from "../components/seo";
import LandingCTA from "../components/landing-cta";
import LandingImage from "../components/landing-image";
import { TabsContext } from "../contexts/TabsContext";

const MainContainerStyled = styled.div`
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  overscroll-behavior: none;
  @media only screen and (min-width: 600px) {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 0;
  }
  @media only screen and (min-width: 900px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media only screen and (min-width: 1200px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media only screen and (min-width: 1800px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  .center-container {
    margin: 0 auto;
    max-width: 1280px;
    /* padding-bottom: 20px; */
    /* padding-top: 32px; */
  }

  /* --spectrum-alias-item-text-padding-top-s: var(--spectrum-global-dimension-static-size-50);
  --spectrum-alias-item-text-padding-top-m: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-item-text-padding-top-xl: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-text-padding-bottom-m: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-text-padding-bottom-l: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-item-text-padding-bottom-xl */

  .landing-message {
    /* https://stackoverflow.com/questions/27510289/css-absolutely-positioned-element-affected-by-margin-of-sibling */
    display: inline-block;
    text-align: center;
    width: 100%;
    color: var(--spectrum-alias-heading-text-color);
    font-size: var(--spectrum-alias-heading-m-text-size);
    font-weight: var(--spectrum-global-font-weight-light);
    margin: 15px auto;
    @media only screen and (min-width: 900px) {
      padding-top: 20px;
      padding-bottom: 30px;
      font-size: var(--spectrum-alias-heading-l-text-size);
    }
    @media only screen and (min-width: 1200px) {
      padding-top: 30px;
      padding-bottom: 40px;
      font-weight: var(--spectrum-global-font-weight-regular);
      font-size: var(--spectrum-alias-heading-xl-text-size);
    }
  }
`;

const LandingContentWrapperStyled = styled.div`
  background: var(--spectrum-global-color-gray-200);
  /* background: var(--spectrum-alias-background-color-default); */
  border-radius: var(--spectrum-global-dimension-size-50);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding-top: var(--spectrum-global-dimension-static-size-200);
  padding-bottom: var(--spectrum-global-dimension-static-size-600);
  padding-left: var(--spectrum-global-dimension-static-size-300);
  padding-right: var(--spectrum-global-dimension-static-size-300);
  display: block;
  height: 100%;

  @media only screen and (min-width: 900px) {
    display: flex;
    padding-left: var(--spectrum-global-dimension-static-size-600);
    padding-right: var(--spectrum-global-dimension-static-size-600);
  }

  .left-column {
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    justify-content: space-between;
    min-width: 236px;
    @media only screen and (min-width: 900px) {
      padding-right: 40px;
    }
  }
  .left-column .title {
    display: flex;
    align-items: center;
  }

  .right-column {
    display: none;
    min-width: 230px;
    max-width: 500px;

    @media only screen and (min-width: 900px) {
      display: flex;
      flex: 1 1 50%;
      justify-content: center;
      align-items: center;
    }
    @media only screen and (min-width: 900px) {
      padding-top: var(--spectrum-global-dimension-size-400);
    }
  }
  .right-column Img {
    border-radius: var(--spectrum-global-dimension-size-25);
  }
`;

const IndexPage = () => {
  // Image
  const { PsLogo, PSScreenDark, PSScreenLight } = useStaticQuery(
    graphql`
      query {
        PsLogo: file(relativePath: { eq: "PsLogo.png" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        PSScreenDark: file(relativePath: { eq: "PSScreenDark.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        PSScreenLight: file(relativePath: { eq: "PSScreenLight.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const { colorScheme } = useContext(ThemeContext);
  const { activeTab, setActiveTab } = useContext(TabsContext);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    if (activeTab !== "home") {
      setActiveTab("home");
    }
  });

  return (
    <>
      <SEO title="CC-Extensions – PS Plugins" />
      <BackgroundImage />
      <MainContainerStyled>
        <div className="center-container">
          <div className="landing-message">Premium plugins for Photoshop</div>
          <LandingContentWrapperStyled>
            <div className="left-column">
              <div className="">
                {/* LandingImage shows ONLY on Phone and Tablet Portrait */}
                <LandingImage theme={colorScheme} breakpoints={breakpoints} />
                <div className="title">
                  <Img
                    fixed={PsLogo.childImageSharp.fixed}
                    alt="Photoshop logo"
                  />
                  <Heading
                    level={breakpoints.desktopUp ? 2 : 3}
                    marginStart="size-150"
                  >
                    Plugins for Photoshop 
                  </Heading>
                </div>
                <Divider size="M" />
                <div className="copy">
                  <p>
                    Hey I&apos;m Davide and I&apos;ve been a Photoshop developer
                    for 10+ years,&nbsp;
                    <SpectrumLink>
                      <a href="https://www.ps-scripting.com/">
                        teaching others too!
                      </a>
                    </SpectrumLink>
                  </p>
                  <p>
                    You can find here tools to enhance and speed up your
                    creative workflow with powerful image processing routines
                    and utilities targeted to Photographers, Retouchers and
                    Designers alike.
                  </p>
                </div>
              </div>
              <LandingCTA />
            </div>
            <div className="right-column">
              {breakpoints.tabletLandscapeUp ? (
                <Img
                  style={{ width: `100%` }}
                  fluid={
                    colorScheme === "dark"
                      ? PSScreenDark.childImageSharp.fluid
                      : PSScreenLight.childImageSharp.fluid
                  }
                />
              ) : null}
            </div>
          </LandingContentWrapperStyled>
        </div>
      </MainContainerStyled>
    </>
  );
};

export default IndexPage;
